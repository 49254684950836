<template>
  <div class="withdraw_record">
    <el-table
      :data="withdrawRecord.data">
      <el-table-column
        width="100px"
        label="申请日期">
        <template v-slot="scope">
          <div>{{ dateToString(scope.row.createtime) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        width="100px"
        label="提现金额">
        <template v-slot="scope">
          <div>{{ scope.row.amount.toMoney() }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="username"
        width="80px"
        label="真实姓名">
      </el-table-column>
      <el-table-column
        prop="withdrawaccount"
        width="120px"
        label="提现账户">
      </el-table-column>
      <el-table-column
        prop="withdrawWay"
        width="80px"
        label="提现方式">
      </el-table-column>
      <el-table-column
        prop="phone"
        width="120px"
        label="手机号">
      </el-table-column>
      <el-table-column
        width="70px"
        label="支付凭证">
        <template v-slot="scope">
          <template v-if="scope.row.payimgurl">
            <el-image
              :src="scope.row.payimgurl"
              style="width: 40px;height: 40px;"
              fit="contain"
              :preview-src-list="[scope.row.payimgurl]"
            ></el-image>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        width="100px"
        label="支付日期">
        <template v-slot="scope">
          <div>{{ dateToString(scope.row.paytime) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        width="80px"
        label="状态">
        <template v-slot="scope">
          <template v-if="scope.row.cancelflag">
            <el-tag type="warning" size="small" effect="dark">
              已撤销
            </el-tag>
          </template>
          <template v-else>
            <el-tag size="small" type="danger" effect="dark" v-if="scope.row.auditflag === 0">
              未审核
            </el-tag>
            <el-tag size="small" effect="dark" v-if="scope.row.auditflag === 1">
              已通过
            </el-tag>
            <template v-if="scope.row.auditflag === 2 || scope.row.auditflag === 3">
              <el-tooltip effect="dark" :content="scope.row.auditmemo" placement="top">
                <el-tag size="small" type="danger" effect="dark">
                  {{ scope.row.auditflag === 2 ? '未通过' : '强制下架' }}
                </el-tag>
              </el-tooltip>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        width="100px"
        label="审核时间">
        <template v-slot="scope">
          <div>{{ dateToString(scope.row.audittime) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作">
        <template v-slot="scope">
          <template v-if="scope.row.auditflag === 0 && !scope.row.cancelflag">
            <el-button
              @click="withdrawRecord.optionsConfig.onClick('cancel', scope.row, scope.$index)"
              size="mini" type="primary"
            >
              撤销
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="sctp-pad-tb15 sctp-flex sctp-flex-fe sctp-bg-white">
      <el-pagination
        :current-page="pageConfig.page"
        :page-size="pageConfig.limit"
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :page-sizes="pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="pageConfig.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {wallet} from "../../../../apis";

export default {
  name: 'withdraw_record',
  data() {
    return {
      withdrawRecord: {
        data: [],
        loadData: () => {
          wallet.withdrawRecord({
            userId: this.user.userId,
            page: this.pageConfig.page,
            limit: this.pageConfig.limit,
          }).then(res => {
            let {data = [], count = 0} = res;
            this.withdrawRecord.data = data;
            this.pageConfig.total = count;
          })
        },
        optionsConfig: {
          options: {
            cancel: ({item, index}) => {
              this.$confirm('确认撤销?', '提示').then(res => {
                wallet.cancelWithdraw({
                  id: item.id,
                }).then(() => {
                  this.$message.success('撤销成功');
                  this.withdrawRecord.loadData();
                });
              });
            },
          },
          onClick: (option, item, index) => {
            if (this.withdrawRecord.optionsConfig.options[option]) {
              this.withdrawRecord.optionsConfig.options[option].call(null,
                {item: item, index: index});
            }
          }
        },
      },
      pageConfig: {
        page: 1,
        limit: 10,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        total: 0,
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.withdrawRecord.loadData();
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.withdrawRecord.loadData();
        }
      }
    }
  },
  methods: {},
  beforeMount() {
    this.withdrawRecord.loadData();
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .withdraw_record /deep/ .el-table__header{width: 100% !important;}
  .withdraw_record /deep/ .el-table__body{width: 100% !important;}
  /*@formatter:on*/
</style>
